import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './testimonialsSection.scss';
import { EnquireUrl, SignupUrl } from '@constants/app';

export default ({
  testimonialsHeadline,
  testimonials,
  assets,
}) => {

  const settings = {
    arrows: true,
    dots: true,
    infinite: testimonials.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }]
  };

  const getAsset = (title) => assets.filter(asset => asset.title === title)[0];

  const withAssets = testimonials.map(testimonial => {
    return {
      ...testimonial,
      url: getAsset(testimonial.assetTitle).file.url,
    }
  });

  return (
    <div className="container flex-center flex-column py-5">
      <div className="row">
        <div className="col-12">
          <h2 className="font-semibold pt-5">{testimonialsHeadline}</h2>
        </div>
      </div>
      <div className="w-100 mt-4 p-3">
        <Slider {...settings}>
          {withAssets.map((testimonial) => (
            <div key={testimonial.testimonialTitle} className="card border-secondary">
              <div className="px-5">
                <img 
                  className="card-img-top" 
                  src={testimonial.url}
                  alt="testimonial logo"
                />
              </div>
              <div className="card-body text-center">
                <p className="font-medium-italic text-dark">{testimonial.testimonialContent}</p>
                <p className="font-semibold">{testimonial.testimonialTitle}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="flex-center">
            <div className="my-5">
              <a href={EnquireUrl} className="btn btn-sm btn-primary">Enquire</a>
              <a href={SignupUrl} className="ml-4 btn btn-sm btn-outline-dark">Sign Up Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
